import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Navigation } from 'styled-icons/boxicons-solid';
import { Title } from '../../../index';

import * as S from './styled';

export default function Map() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            map {
              title
            }
          }
        }
      }
      staticMap {
        center
        childFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  function onWazeClickHandler() {
    const baseLink = 'https://www.waze.com/ul?navigate=yes&zoom=17';
    const routeLink = `${baseLink}&q=${data.staticMap.center}`;
    window.open(routeLink, '_blank');
  }

  function onMapsClickHandler() {
    const baseLink = 'https://maps.google.com?saddr=Current+Location';
    const routeLink = `${baseLink}&daddr=${data.staticMap.center}`;
    window.open(routeLink, '_blank');
  }

  return (
    <S.Card>
      <S.Map fluid={data.staticMap.childFile.childImageSharp.fluid} />
      <S.Container>
        <Title>{data.site.siteMetadata.contacts.map.title}</Title>
        <S.Actions>
          <S.Action onClick={onWazeClickHandler}>
            <Navigation size={20} />
            Waze
          </S.Action>
          <S.Action onClick={onMapsClickHandler}>
            <Navigation size={20} />
            Google Maps
          </S.Action>
        </S.Actions>
      </S.Container>
    </S.Card>
  );
}
