import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Send } from 'styled-icons/material';

import { Title } from '../../../index';
import { useLoader, useToast } from '../../../../contexts';
import { $form } from '../../../../services';

import Input, { Select } from './Input';
import * as S from './styled';

export default function Form() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            form {
              title
              subjects {
                label
                value
              }
              formspree
              messages {
                success
                fail
              }
            }
          }
        }
      }
    }
  `);
  const [payload, setPayload] = useState({
    Nome: '',
    'E-mail': '',
    Telefone: '',
    Assunto: '',
  });
  const { setState: setLoader } = useLoader();
  const { setConfig: setToast } = useToast();

  function onFormSubmitHandler(e) {
    e.preventDefault();

    setLoader((prev) => ({ ...prev, label: 'Enviando...', status: true }));
    return $form
      .send(data.site.siteMetadata.contacts.form.formspree)(payload)
      .then(() =>
        setToast((prev) => ({
          ...prev,
          message: data.site.siteMetadata.contacts.form.messages.success,
          status: true,
        })),
      )
      .catch(() =>
        setToast((prev) => ({
          ...prev,
          message: data.site.siteMetadata.contacts.form.messages.fail,
          theme: 'error',
          status: true,
        })),
      )
      .finally(() => {
        setLoader((prev) => ({ ...prev, status: false }));
      });
  }

  return (
    <S.Card>
      <Title>{data.site.siteMetadata.contacts.form.title}</Title>
      <S.Form method="POST" onSubmit={onFormSubmitHandler}>
        <Input
          label="Nome*:"
          name="name"
          placeholder="Exemplo: John Doe"
          value={payload.Nome}
          required
          onInput={$form.formInputHandler(setPayload)}
        />
        <Input
          label="Email*:"
          name="email"
          placeholder="john.doe@email.com"
          type="email"
          value={payload['E-mail']}
          required
          onInput={$form.formInputHandler(setPayload)}
        />
        <Input
          label="Telefone*:"
          name="phone"
          placeholder="Exemplo: (11) 91234-1234"
          type="phone"
          value={payload.Telefone}
          required
          onInput={$form.formInputHandler(setPayload)}
        />
        <Select
          label="Assunto:"
          name="subject"
          value={payload.Assunto}
          options={data.site.siteMetadata.contacts.form.subjects}
          required
          onInput={$form.formInputHandler(setPayload)}
        />
        <S.Footer>
          <S.Submit type="submit" cta>
            <Send size={20} />
            Enviar Mensagem!
          </S.Submit>
          <S.Warning>* Campos Requeridos</S.Warning>
        </S.Footer>
      </S.Form>
    </S.Card>
  );
}
