import styled from 'styled-components';

export const Select = styled.select``;

export const Option = styled.option`
  &.--disabled {
    color: ${({ theme }) => theme.colors.dark.alpha(0.48)};
  }
`;

export const Field = styled.input``;

export const Label = styled.label`
  display: block;
  line-height: ${({ theme }) => theme.lineHeights.title};
  margin: 0 0 0.8rem 0;
`;

export const Input = styled.span`
  display: block;
  width: 100%;

  :not(:last-of-type) {
    margin: 0 0 2.4rem 0;
  }

  > ${Field}, > ${Label}, > ${Select} {
    width: 100%;

    &,
    > ${Option}:not(.--disabled) {
      color: ${({ theme }) => theme.colors.dark.hex()};
      font-size: ${({ theme }) => theme.sizes.p};
    }
  }

  > ${Field}, > ${Select} {
    background-color: ${({ theme }) => theme.colors.light.hex()};
    border: 1px solid ${({ theme }) => theme.colors.dark.alpha(0.16)};
    box-shadow: ${({ theme }) => theme.shadows.soft};
    min-height: ${({ theme }) => theme.sizes.btn};
    padding: 0.8rem 1.6rem;
  }
`;
