import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ChevronLeft } from 'styled-icons/material';

import * as S from './styled';

export default function Accordion({ items }) {
  const [active, setActive] = useState(0);

  function togglerClickHandler(index) {
    setActive(index);
  }

  return (
    <S.Accordion>
      {items.map(({ title, content }, i) => (
        <S.Item className={active === i && '--active'} key={i}>
          <S.Toggler onClick={() => togglerClickHandler(i)}>
            <S.Label>{title}</S.Label>
            <S.Icon>
              <ChevronLeft size={24} />
            </S.Icon>
          </S.Toggler>
          <S.Collapsible>{content}</S.Collapsible>
        </S.Item>
      ))}
    </S.Accordion>
  );
}

Accordion.defaultProps = {
  items: [],
};
Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: '',
      content: '',
    }),
  ),
};
